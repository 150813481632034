var app = {

    isLocal: window.location.href.includes('local'),
    currentUser: null,
    dragula: null,
    dragulaRight: null,
    currentElToEdit: null,  
    currentImgEl: null,
    tempTextAreaForEdit: null,  
    isShowingEditable:false,
    isLoggingOut: false,
    portalsStyles: null,

    init: function(){

        var isLoginPage = $('body').hasClass('login');

        if(isLoginPage){
            app.setupToastr();
            app.setupLogin();
        }
        else {

            setTimeout(() => {
                $('.editorBackdrop').remove();
            }, 500);

            this.getUser();
            this.setupGlobalAjaxError();
            this.setupToastr();
            this.setupSidebar();
            this.setupSearch();
            this.setupImageSearch();
            this.prepareBeforePageUnload();

        }

    },
    
    getUser(){
        $.post( "functions.php", 
            { method: "getUser"}
        )
        .done(function( user ) {
            user = JSON.parse(user);
            app.currentUser = user;
        });
    },

    setupLogin(){

        $('.loginForm button').click(function(e){
            e.preventDefault();

            $.post( "functions.php", 
                { 
                    method: "login", 
                    data: {
                        user: $('[name="user"]').val(),
                        pass: $('[name="pass"]').val()
                    }
                }
            )
            .done(function( result ) {
                result = JSON.parse(result);
                console.log('result', result);
                if(result.success == false){
                    toastr.error(result.msg);
                }
                else {

                    $('body').addClass('animate');
                    setTimeout(() => {
                        window.location.href = "/editor.php";
                    }, 500);

                }
                
            });
            
        });

        var  queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var wasLoggedOut = urlParams.get('loggedOut')        
        if(wasLoggedOut != null){
            toastr.info('Sie wurden ausgeloggt.<br><small>(Backup wurde gespeichert)</small>');
        }

    },

    setupGlobalAjaxError(){

        $( document ).on( "ajaxError", function(jqXHR, textStatus, errorThrown) {
            if(textStatus.status == 401){
                //no valid login, redirect to login page
                app.createBackup();
                window.location.href = "index.php?loggedOut=1";
            }
        } );

    },

    setupToastr(){
        toastr.options = {
            "debug": false,
            "positionClass": "toast-bottom-left",
            "onclick": null,
            "fadeIn": 300,
            "fadeOut": 1000,
            "timeOut": 5000,
            "extendedTimeOut": 1000
        }
    },

    setupSidebar: function(){

        //global accordeon
        $('.sidebar .area .title').click(function(e){
            $('.sidebar .area').removeClass('active');
            $(e.target).parent().addClass('active');
        });

        //TEMPLATE-SETTINGS
        $.post( "functions.php", { method: "getNewsletterTemplates"})
        .done(function( result ) {

            result = JSON.parse(result);

            for(var template of result['templates']){
                $('[name="newsletterTemplates"]').append('<option value="'+ template +'">' + template + '</option>');
            }
            
            app.portalsStyles = result['portals'];
            for(var portal in app.portalsStyles){
                $('[name="portal"]').append('<option value="'+ portal +'">' + portal + '</option>');
            }

            app.loadNewsletterTemplate();
            app.checkForBackup();
        });

        //portal changed
        $('[name="portal"]').on('change', function(e){             
            // $('[name="newsletterTemplates"]').trigger('change');
            app.prepareStylingForPortal();
        });

        //template selected
        $('[name="newsletterTemplates"]').on('change', function(e){             
            app.loadNewsletterTemplate();
            app.checkForBackup();
        });


        //SEARCH
        $(".checkIn").datepicker({
            dateFormat: 'dd.mm.yy'
        })
        .datepicker('setDate', '+7');

        $(".checkOut").datepicker({
            dateFormat: 'dd.mm.yy'
        })
        .datepicker('setDate', '+10');
 
        //TRIGGER EDIT-CONTENT
        // $(document).on('click', '.editBackdrop i', function(e){
        $(document).on('click', '.editBackdrop', function(e){
            e.preventDefault();

            app.isShowingEditable = true;

            var target = $(e.target);
            if(!target.hasClass('editableContent')){
                target = target.parents('.editableContent');
            }
            app.currentElToEdit = $(target);
            app.currentElToEdit.addClass('hide');
            app.tempTextAreaForEdit = $('<textarea style="width:100%;height:100px;resize: vertical;"></textare>');
            var currentVal =target.html();
            // currentVal = currentVal.replace('<span class="editBackdrop"><i class="fa-solid fa-pen"></i></span>', '');
            currentVal = currentVal.replace('<span class="editBackdrop"></span>', '');
            currentVal = currentVal.trim();
            //"&nbsp;" to ""
            currentVal = currentVal.replace("&nbsp;","");
            //"<br>" to new line
            currentVal = currentVal.replace(/<br>/g,"\n");
            app.tempTextAreaForEdit.val(currentVal);
            app.tempTextAreaForEdit.insertAfter(target);;

            app.tempTextAreaForEdit.on('keyup', function(e){

                if (e.key === "Escape") {
                    $('.preview').trigger('click');
                    return;
                }

                var changedVal = $(e.target).val();
                //new line to "<br>"
                changedVal = changedVal.replace(/(?:\r\n|\r|\n)/g, '<br>');
                if(changedVal.length==0){changedVal = "&nbsp;"}
                // var newHtmlContent = '<span class="editBackdrop"><i class="fa-solid fa-pen"></i></span>' + changedVal;
                var newHtmlContent = '<span class="editBackdrop"></span>' + changedVal;
                app.currentElToEdit.html(newHtmlContent);
            });

        })

        //codeview show
        $('.showCode').click(function(){

            $('.codeview').addClass('show');
            $('.backdrop').addClass('show');

            $.post( "functions.php", { 
                method: "getGlobalTemplateCode"
            })
            .done(function( result ) {
                result = JSON.parse(result);
                
                //clone the html
                var editedTemplate = $('.toEdit').clone();

                //append to dom, so we can manipulate it
                $('body').append('<div class="templateToGetCode" style="display:none;">' + editedTemplate.html() + '</div>');

                //remove ".innerTemplate"
                $('.templateToGetCode').find('.innerTemplate').remove();
                $('.templateToGetCode').find('title').text($('.newsletterTitle').val());

                //remove .editBackdrop
                $('.templateToGetCode').find('.editBackdrop').remove();

                //remove demo-stuff
                $('.templateToGetCode').find('.demoStyle').removeClass('demoStyle');
                $('.templateToGetCode').find('.demo').remove();

                //remove tr.action
                $('.templateToGetCode').find('tr.actions').remove();

                //remove ".replaceImage"
                $('.templateToGetCode').find('.replaceImage').remove();

                //clone the altered html
                editedTemplate = $('.templateToGetCode').clone();
                $('.templateToGetCode').remove();

                //if found any unfilled "hotel-space", warn user
                var foundDemoHotels = editedTemplate.find('.canDrop:not(.hasItem)').length;
                if(foundDemoHotels>0){
                    var msgs = [
                        'Achtung: Es wurde '+ foundDemoHotels +' Demo-Hotel gefunden',
                        'Achtung: Es wurden '+ foundDemoHotels +' Demo-Hotels gefunden'
                    ];
                    var msg = foundDemoHotels == 1 ? msgs[0] : msgs[1];
                    alert(msg);
                }

                //switch <div class="head"> WITH <head>
                editedTemplate.find('.head').replaceWith(function(){
                    return $("<head>").append($(this).contents());
                });

                //switch <div class="body" style="...."> WITH <body style="...">
                var bodyStyle = editedTemplate.find('.body').attr('style');
                editedTemplate.find('.body').replaceWith(function(){
                    return $("<body>").append($(this).contents());
                });
                editedTemplate.find('body').attr('style', bodyStyle);

                //add to and bottom HTML to 'html' --> <html.....>
                var htmlCodeRaw = result.top + "\n" + editedTemplate.html() + "\n" + result.bottom;
                var hasMissingValues = htmlCodeRaw.search('REQUIRED-VALUE') > -1;
                htmlCode  = html_beautify(htmlCodeRaw, { indent_size: 2, space_in_empty_paren: true } );


                $('.codeTabContent[data-type="html"]').find('pre').text(htmlCode);

                //now select the code for user, he have to still copy manually
                var doc = window.document, sel, range;
                if (window.getSelection && doc.createRange) {
                    sel = window.getSelection();
                    range = doc.createRange();
                    range.selectNodeContents($('.codeTabContent[data-type="html"]').find('pre')[0]);
                    sel.removeAllRanges();
                    sel.addRange(range);
                } else if (doc.body.createTextRange) {
                    range = doc.body.createTextRange();
                    range.moveToElementText($('.codeTabContent[data-type="html"]').find('pre')[0]);
                    range.select();
                }

                if(hasMissingValues){
                    alert('ATTENTION: Template is missing some values! Look out for "REQUIRED-VALUES"');
                }

                //CONVERT TO PLAIN-TEXT
                var plain = '';
                $('[plaintext]').each(function(i, el){

                    if($(el).parents('.innerTemplate').length>0){ return; }
                    
                    var txt = '';                    
                    if(el.nodeName == 'A'){
                        txt = $(el).text().trim();
                        var href = $(el).attr('href');
                        var glue = txt != '' ? ': ' : '';
                        txt = txt + glue + href;
                    }
                    else {
                        txt = $(el).text().trim() + "\n";
                    }
                    plain += txt;

                    if( $(el).attr('plaintextspacer') != undefined ){
                        plain += "\n\n";
                    }

                });

                //plain += "\n!!--FOOTER-CONTENT-ANPASSEN--!!";

                $('.codeTabContent[data-type="text"]').find('pre').text(plain);

            });
        });        
        //toggle showcode HTML / TEXT
        $('.codeTab').click(function(){
            var type = $(this).data('type');
            $('.codeTab').removeClass('active');
            $('.codeTabContent').removeClass('active');

            $('.codeTab[data-type="'+type+'"]').addClass('active');
            $('.codeTabContent[data-type="'+type+'"]').addClass('active');

            //now select the code for user, he have to still copy manually
            var doc = window.document, sel, range;
            if (window.getSelection && doc.createRange) {
                sel = window.getSelection();
                range = doc.createRange();
                range.selectNodeContents( $('.codeTabContent[data-type="'+type+'"]').find('pre')[0] );
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (doc.body.createTextRange) {
                range = doc.body.createTextRange();
                range.moveToElementText( $('.codeTabContent[data-type="'+type+'"]').find('pre')[0] );
                range.select();
            }

        });


        var _closeCodeView = function(){
            $('.codeview .code pre').text('');
            $('.codeview').removeClass('show');
            $('.backdrop').removeClass('show');
        }
        $('.backdrop').click(function(){                  
            _closeCodeView();
            app._closeImageModal();
        });
        $('.codeview .close').click(function(){
            _closeCodeView();
        });

        //highlight editable content
        $('.showEditable').click(function(){
            if(app.isShowingEditable == true){
                app.isShowingEditable = false;
                $('.toEdit').find('.editableContent').removeClass('show');
                $('.toEdit').find('.replaceableImage').removeClass('showEditable');
            }
            else {
                app.isShowingEditable = true;
                $('.toEdit').find('.editableContent').addClass('show');
                $('.toEdit').find('.replaceableImage').addClass('showEditable');
            }
        });
        //when clicked in .preview (where the template is in)
        $('.preview').click(function(e){
            
            //when user is clicking textarea to edit, return
            if($(e.target).is(app.tempTextAreaForEdit)){return;}
            
            if(app.isShowingEditable == true){
                app.isShowingEditable = false;
                $('.toEdit').find('.editableContent').removeClass('show');
                $('.toEdit').find('.replaceableImage').removeClass('showEditable');

                if(app.tempTextAreaForEdit != null){
                    app.currentElToEdit.removeClass('hide');
                    app.tempTextAreaForEdit.remove();
                    app.tempTextAreaForEdit = null;
                }
            }
            
        });

        //refreshTemplate
        $('.refreshTemplate').click(function(){
            if (confirm("Template wird zurückgesetzt, sind Sie sicher?") == true) {
                $('[name="newsletterTemplates"]').trigger('change');
                toastr.info('Template wieder hergestellt');
              }
        });

        //logout
        $('.logout').click(function(){

            app.isLoggingOut = true;

            // app.createBackup();
            if (confirm("Soll ein Backup für dieses Template erstellt werden?") == true) {
                app.createBackup();
            }

            $.post( "functions.php", { method: "logout"}).done(function( result ) {
                result = JSON.parse(result);
                console.log(result);
                if(result.success == true){
                    location.reload();                    
                }
            });
        });

        $('.preview').on('click', '.actions .action i', function(e){
            var type = $(e.target).data('type');
            var mainEl = $(e.target).parents('.canReorder');
            if(type=="copy"){
                var clone = mainEl.clone();
                clone.insertAfter(mainEl);
                setTimeout(() => { app.setupDrags(); }, 500);
            }
            else if(type=="delete"){
                mainEl.remove();
                //when image is deleted, unsplash-user-reference needs to be updated
                app._handleUnsplashUserLinks();
            }
        });

        //image replace button clicked
        $('.preview').on('click', '.replaceImage', function(e){
            var target = $(e.target);
            if(target.hasClass('fa')){
                target = target.parent();
            }

            app.currentImgEl = $(target).parent().find('img');
            $('.backdrop').addClass('show');
            $('.imageModal').addClass('show');
            
        });

        //DEV-ONLY (switch to hotelsearch and trigger search)
        if(app.isLocal){
            setTimeout(() => {
                $('.area.search .title').trigger('click');        
                $('.area.search [name="location"]').val('wien');
                $('.area.search button').trigger('click');
            }, 500);
        }
    },

    setupSearch: function(){

        var _search = function(){
            
            //clear prev search results
            $('.searchResultList').empty();

            if($("[name='location']").val().length == 0){
                toastr.error('Ort/Stadt fehlt');
                return;
            }

            $('.preloaderwrapper').addClass('show');

            //format dates
            var checkInParts = $(".checkIn").val().split('.');
            var checkIn = checkInParts[2] + '-' + checkInParts[1] + '-' + checkInParts[0];
            var checkOutParts = $(".checkOut").val().split('.');
            var checkOut = checkOutParts[2] + '-' + checkOutParts[1] + '-' + checkOutParts[0];

            $.post( "functions.php", 
                { 
                    method: "search", 
                    data: {
                        portal: $("[name='portal']").val(),
                        location: $("[name='location']").val(),
                        checkIn: checkIn,
                        checkOut: checkOut
                    }
                }
            )
            .done(function( result ) {
                app.prepareResults(JSON.parse(result));
            });
        };

        //trigger search by hit ENTER on input
        $('[name="location"]').keypress(function (e) {
            if (e.which == 13) {
                _search();
                return false;
            }
          });

        //trigger search by clicking button
        $('button.search').click(()=>{
            _search();            
        });

        // // //TRIGGER SEARCH FOR NOW FOR DEV
        // setTimeout(() => {
        //     console.log('TRIGGER SEARCH FOR DEV FOR NOW');
        //     $('.search').click();
        // }, 800);
    },

    setupImageSearch: function(){
        //on click search button

        var _doImageSearch = function(){
            var imageKeyword = $('[name="imageKeyword"]').val();

            if(imageKeyword.length == 0){
                toastr.error('Bitte Suchfeld ausfüllen');
                return;
            }

            $('.imageResults').empty();
            
            $.post( "functions.php", 
                { 
                    method: "imageSearch", 
                    data: {
                        imageKeyword: imageKeyword
                    }
                }
            )
            .done(function( imageResult ) {
                
                imageResult = JSON.parse(imageResult);

                $('.imageResults').addClass('show');

                if(imageResult.total>0){
                    var imageResultsEl = $('.imageModal').find('.imageResults');
                    for(var el of imageResult.results){
                        var attributionElForUnsplash = '<div class="unsplashAttribution"><a target="_blank" href="'+ el.user.links.html +'?utm_source=tpo-newsletter&utm_medium=referral">'+ el.user.name +'</a> <span>on</span> <a  target="_blank" href="https://unsplash.com/?utm_source=tpo-newsletter&utm_medium=referral">Unsplash</a></div>';
                        var dataAttr = JSON.stringify({
                            user: {name: el.user.name, link: el.user.links.html},
                            downloadStatsLink: el.links.download_location
                        });
                        dataAttr = "data-imagedata='"+ dataAttr +"'";
                        imageResultsEl.prepend('<div '+ dataAttr +' class="img"><img src="'+ el.urls.raw + "&fm=jpg&w=150&h=150&fit=crop" +'&q=1" />'+ attributionElForUnsplash +'</div>');
                    }
                }
                else {
                    //nothing found
                    toastr.error('keine passenden Bilder gefunden');
                }
            });
        }

        $('body').on('keyup', '[name="imageKeyword"]', function(e){
            if (e.which == 13) {
                _doImageSearch();
            }
        });
        
        $('body').on('click', '.doImageSearch', function(e){
            _doImageSearch();
        });

        //on select image
        $('body').on('click', '.imageResults > .img > img', function(e){    
            
            //add preloader
            $('<div class="preloaderwrapper show" style="position: absolute;top: 45%;"><div class="preloader"></div></div>').insertBefore(app.currentImgEl);

            var imageWidth = app.currentImgEl.width();
            var imageHeight = app.currentImgEl.height();
            var selectedImageUrl = $(e.target).attr('src');///
            selectedImageUrl = selectedImageUrl.split('&w=');
            selectedImageUrl = selectedImageUrl[0] + "&w=" + imageWidth + '&h=' + imageHeight + '&fit=crop&q=50';
            app.currentImgEl.attr('src', selectedImageUrl);

            //add unsplash-user-data
            var imageData = $(e.target).parent().data('imagedata');
            app.currentImgEl.attr('data-unsplashuserinfo', JSON.stringify({name: imageData.user.name, link: imageData.user.link}));
            
            //remove preloader
            app.currentImgEl[0].onload = function(){
                app.currentImgEl.parent().find('.preloaderwrapper').remove();
            }

            app._handleUnsplashUserLinks();

            $('.backdrop').trigger('click');

            /*
            trigger "unsplash-download-stats" (required by their api-guidelines), 
            ATTENTION: WE JUST CALLING THIS ENDPOINT, THERE IS NO RESPONSE TO HANDLE, THIS ONLY TRIGGERS SOME STATISTICS FOR UNSPLASH            
            */

            $.post( "functions.php", 
                { 
                    method: "trackUnsplashDownload", 
                    data: {
                        downloadStatsLink: imageData.downloadStatsLink
                    }
                }
            )
            .done(function() {/*nothing to do here*/});

        });

        $('body').on('click', '.imageModalAction .fa-times', function(){
            $('.backdrop').trigger('click');
        });

    },

    _handleUnsplashUserLinks: function(){
        console.log('_handleUnsplashUserLinks');
        if($('.unsplash').find('.imageSources').length==0){
            $('.unsplash td').append('<div class="imageSources" style="margin:4px;"><a style="color:#000;" target="_blank" href="https://unsplash.com/?utm_source=tpo-newsletter&utm_medium=referral">Bildquelle</a>: <span class="userList"></span></div>');
        }

        var unsplashusers = {};
        $('[data-unsplashuserinfo]').each(function(i,el){
            var userdata = JSON.parse($(el).attr('data-unsplashuserinfo'));                

            //reormat the user link because, c#-template-rendering does not like "@"-sign
            const urlAttr = new URL(userdata.link);
            var pathNameSplit = urlAttr.pathname.split("/@");
            var pathName = pathNameSplit.length>1 ? pathNameSplit[1] : urlAttr.pathname;
            var newUserUrl = urlAttr.origin + "/" + pathName;
            unsplashusers[userdata.name] = newUserUrl;
        });
        
        $('.unsplash .userList').empty();

        for(var unsplashuserName in unsplashusers){  
            var separation = '';   
            if($('.unsplash .userList').children().length >0){
                separation = ', ';
            }
            $('.unsplash .userList').append( $('<a class="unsplashuser" style="color:#000;" target="_blank" href="'+ unsplashusers[unsplashuserName] +'?utm_source=tpo-newsletter&utm_medium=referral">'+ separation + unsplashuserName +'</a>') );
        }

        if($('.unsplash .userList').children().length ==0){
            $('.unsplash td').empty();
        }

    },

    _closeImageModal: function(){
        // $('[name="imageKeyword"]').val('');
        $('.imageModal').removeClass('show');
    },

    checkForBackup: function(){

        $.post( "functions.php", { 
            method: "checkBackup",
            data:{
                userName: app.currentUser,
                fileName: $('[name="newsletterTemplates"]').val(),
            }
        })
        .done(function( result ) {
            result = JSON.parse(result);
            if(result != false){
                //there is a backup
                if (confirm('\n Backup für "' + result.name + '" gefunden.\n Soll dieses geladen werden?\n\n (wird automatisch gelöscht).') == true) {

                    //load backup
                    setTimeout(function(){
                        $('.preview').empty();
                        $('.preview').append($(result.content));
                      
                        app.prepareStylingForPortal();

                        setTimeout(() => {
                            app.setupDrags();
                        }, 500);
                        toastr.info('Backup hergestellt');

                    },500);
                    
                }

                //delete backup
                $.post( "functions.php", { 
                    method: "deleteBackup", 
                    data:{
                        userName: app.currentUser,
                        name: result.name
                    }
                })
                .done(function( result ) {
                    //toastr.info('Backup gelöscht');
                });


            }
        });

    },

    prepareResults: function(result){

        $('.preloaderwrapper').removeClass('show');

        if(result.success == false || result.data.length==0){
            toastr.error("Keine Ergebnisse");
        }
        else {
            var data = result.data;            

            //add to search result
            var resultToCopy = $('.result.toCopy')
            for(var d of data){
                var resultToCopyClone = resultToCopy.clone().removeClass('toCopy');
                resultToCopyClone.find('.hotelName').html(d.hotelName);
                resultToCopyClone.find('.location .name').html(d.location.name);
                resultToCopyClone.find('.location .country').html(d.location.country);
                
                var stars = '';
                for(var i=0;i<d.stars;i++){
                    stars += '<span style="color: #f7d652;font-size:16px;">★</span>';
                }
                resultToCopyClone.find('.stars').html(stars);
                // resultToCopyClone.find('.stars .star').html(d.stars);

                resultToCopyClone.find('.priceFrom').html(d.priceFrom);
                var imageUrl = d.image;
                var imageWidth = resultToCopyClone.find('img').attr('width');
                var imageHeight = resultToCopyClone.find('img').attr('height');
                imageUrl = imageUrl.replace('WIDTH', imageWidth);
                imageUrl = imageUrl.replace('HEIGHT', imageHeight); 
                resultToCopyClone.find('img').attr('src', imageUrl);
                resultToCopyClone.attr('entry', JSON.stringify(d));
                $('.searchResultList').append(resultToCopyClone);
            }

            app.setupDrags();

        }

    },

    setupDrags: function(){
        
        if(app.dragula != null){
            app.dragula.destroy();
        }

        var bags = [];
        bags.push(document.querySelector('.searchResultList'));
        bags.push(...[].slice.call(document.querySelectorAll(".canDrop")));                
        
        app.dragula = dragula(
            bags,            
            { 
            //######           
            copy: function (el, source) {
                // To copy only elements in left container, the right container can still be sorted
                return $(source).hasClass('searchResultList');
            },
            copySortSource: false,
            accepts: function(el, target, source, sibling) {
                // To avoid draggin from right to left container
                // if($(target).hasClass('hasItem')){ return false } //there is already an item in there                
                return !$(target).hasClass('searchResultList');
            },
            removeOnSpill: true,
            moves: function (el, source, handle, sibling) {   
                if($(el).hasClass('innerTemplate')){
                    //preventing delete "innerTemplate", we need that for styling                                    
                    return false;
                }
                return true;
            },
        });

        var shadowWidth=10;
        var shadowHeight=10;
        app.dragula.on('shadow', function(el,target,source){            
            if($(target).is($(source))){ 
                return;
            }
            
            var innerTemplate = $(target).find('.innerTemplate');
            
            $(el).css({
                'width': shadowWidth - 8,
                'height': innerTemplate.height() - 8,
                'border': '4px dotted red'
            });

        });

        app.dragula.on('remove', function(el,target,source,sibling){
            // console.log('REMOVE');
            $(target).removeClass('hasItem');
        });

        app.dragula.on('over', function(el,target,source){
            shadowWidth = $(target).find('.innerTemplate').outerWidth();
            shadowHeight = $(target).find('.innerTemplate').outerHeight();

            if($(target).is($(source))){ 
                return;
            }
            
            $(target).addClass('hideInnerTemplate');

            //when another item is there, hide it for now
            $(target).find('.clone').addClass('hideTemp');
        });

        app.dragula.on('drop', function(el,target,source){
            // console.log('DROP');
        
            var dataEntry = JSON.parse($(el).attr('entry'));
            var innerTemplate = $(target).find('.innerTemplate').clone().removeClass('innerTemplate').addClass('clone').css('opacity', 1);
                
            for(var indexName in dataEntry){                
                var htmlEl = innerTemplate.find('.' + indexName);

                if(htmlEl.length>0){

                    if(htmlEl[0].nodeName == 'IMG'){
                        var imageUrl = dataEntry[indexName];                        
                        var imageWidth = $(htmlEl).attr('width');
                        var imageHeight = $(htmlEl).attr('height');
                        imageUrl = imageUrl.replace('WIDTH', imageWidth);
                        imageUrl = imageUrl.replace('HEIGHT', imageHeight);                        
                        $(htmlEl).attr('src', imageUrl, imageWidth, imageHeight);
                        $(htmlEl).attr('alt', dataEntry['hotelName']);
                    }
                    else {
                        
                        if(indexName == 'link'){
                            $(htmlEl).attr('href', dataEntry[indexName]);
                            
                        }
                        else if(indexName == 'stars'){
                            var content = '';
                            for(var i=0;i<dataEntry[indexName];i++){
                                content += '<span style="color: #f7d652;font-size: 27px;">&#9733;</span>';
                            }                                
                            $(htmlEl).html(content);
                        }
                        else {
                            $(htmlEl).html(dataEntry[indexName]);
                        }

                    }
                }

                
            }
            

            $(innerTemplate).attr('entry', JSON.stringify(dataEntry));
            $(el).replaceWith($(innerTemplate));       
            
            $(target).addClass('hasItem');

            // //if there is another .clone, remove the old one
            if($(target).find('.clone:not(.gu-transit)').length==2){
                $(target).find('.clone:not(.gu-transit)').first().remove();
            }

        });
        
        app.dragula.on('out', function(el,target,source){
            //el was dragged out of container OR dropped!!!!
            // console.log('OUT');

            $(target).removeClass('hideInnerTemplate');
            
            //if no "".clone", show "innerTemplate" again            
            if($(target).find('.clone:not(.gu-transit)').length==0){
                $(target).removeClass('hasItem');
            }
            else {
                $(target).addClass('hasItem');
            }

            //when another item is there, show this instead of innerTemplate
            $(target).find('.clone').removeClass('hideTemp');

        });


        ////HANDLE RIGHT-SIDE ONLY (for reordering (via ".handle"-class))
        if(app.dragulaRight != null){
            app.dragulaRight.destroy();
        }
        
        app.dragulaRight = dragula([$('.reorderContainer')[0]], {
            moves: function (el, container, handle) {                
                return $(handle).hasClass('handle');                
            }
        });
    },

    loadNewsletterTemplate: function(){

        //clean view
        $('.toEdit').remove();

        $.post( "functions.php", { 
            method: "getNewsletterTemplateContent",
            data: {
                fileName: $('[name="newsletterTemplates"]').val()
            }
        })
        .done(function( result ) {
            var htmlResult = JSON.parse(result);            

            //we only need the inner table of the template
            $('body').append('<div id="temporaryTemplToPlace" style="display:none;">'+ htmlResult +'</div>');
            var innerTemplate = $('#temporaryTemplToPlace .toEdit').clone();
            //add edit-backdrop
            // innerTemplate.find('.editableContent').prepend('<span class="editBackdrop"><i class="fa-solid fa-pen"></i></span>');
            innerTemplate.find('.editableContent').prepend('<span class="editBackdrop"></span>');

            //add DEMO-signals
            $('<div class="demo">DEMO</div>').insertBefore(innerTemplate.find('.innerTemplate').find('img'));
            innerTemplate.find('.innerTemplate').find('img').parent().addClass('demoStyle');

            //add '.handle' to reorder for dragula                        
            innerTemplate.find('.canReorder').each(function(i, el){
                
                $(el).css('position','relative');

                $(`<tr class="actions">
                        <td>
                            <span class="action" title="verschieben"><i class="handle fa-solid fa-up-down-left-right" data-type="drag"></i></span>
                        </td>
                        <td>
                            <span class="action" title="duplizieren"><i class="copy fa-solid fa-copy" data-type="copy"></i></span>
                        </td>
                        <td>
                            <span class="action" title="löschen"><i class="delete fa-solid fa-times" data-type="delete"></i></span>
                        </td>
                        
                   </tr>`).insertBefore($(el).find('tr').first());
            });

            //add 'image search api-ui' for '.replaceableImage'
            innerTemplate.find('.replaceableImage').each(function(i, el){                
                $(el).prepend($('<div class="replaceImage"><i class="fa fa-solid fa-magnifying-glass"></i></div>'));
                $(el).css('position','relative');
            });

            $('.preview').append(innerTemplate);

            $('#temporaryTemplToPlace').remove();

            app.prepareStylingForPortal();
            

            setTimeout(function(){
                app.setupDrags();
            },500);

        });
    },

    prepareStylingForPortal: function(){
        //prepare portal-stylings, if any            
        $('.body').find('[data-portal-style]').each(function(i,el){                        
            var selectedPortal = $('[name="portal"]').val();
            var stylesToChange = $(el).data('portal-style').split(',');
            if(app.portalsStyles[selectedPortal] != undefined){

                var portalStyles = app.portalsStyles[selectedPortal];    

                for(var styleToChange of stylesToChange){
                    if(portalStyles[styleToChange] != undefined){

                        var originalStyleBackup = {};

                        for(var style in portalStyles[styleToChange]){
                            //get current style for backup...
                            var currentStyleVal = $(el).css(style);
                            originalStyleBackup[style] = currentStyleVal;

                            //set style to el
                            $(el).css(style, portalStyles[styleToChange][style]);
                        }
                        
                        //if possible set style-backup to el
                        if($(el).attr('data-originalstylebackup') == undefined){
                            $(el).attr('data-originalstylebackup', JSON.stringify(originalStyleBackup));
                        }
                    }
                    else {
                        //styles for this el is undefined, fallback to attr 'data-originalstylebackup' if set                        
                        if($(el).attr('data-originalstylebackup') != undefined){
                            var styles = $(el).attr('data-originalstylebackup');
                            styles = JSON.parse(styles);
                            for(var style in styles){
                                $(el).css(style, styles[style]);
                            }
                        }

                    }
                }

            }

            //update each link with attr '[portalLinkToParse]' with new domain-target like "trip.de"
            $('[portalLinkToParse]').each(function(i,el){                
                const urlAttr = new URL($(el).attr('href'));                
                var newUrl = urlAttr.protocol + '//www.' + selectedPortal + urlAttr.pathname + urlAttr.search;
                $(el).attr('href', newUrl);
            });

        });
    },

    prepareBeforePageUnload: function(){

        //no need for backup when local (DEV)
        if(app.isLocal){ return; }

        window.addEventListener("beforeunload", (event) => {  
            if(app.isLoggingOut == false){
                app.createBackup();
            }
        });
    },

    createBackup: function(){

        $.post( "functions.php", { 
            method: "createBackup", 
            data:{
                userName: app.currentUser,
                fileName: $('[name="newsletterTemplates"]').val(),
                content: $('.toEdit')[0].outerHTML
            }
        })
        .done(function( result ) {
            
        });

    }

};

$(function() {
    app.init();
});